<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{'drawer-mini': !DRAWER_STATE}">
    <v-list>
      <template v-for="(item, i) in items">
      <div v-if="item.roles === user.roles || item.roles2 === user.roles || item.roles3 === user.roles" :key="i">
        <v-row
          v-if="item.heading"
          :key="item.heading"
          align="center">
          <v-col cols="6" class="py-5">
            <span
              style="padding-left: 8px"
              class="text-body-1 subheader"
              :class="(item.heading && DRAWER_STATE) ? 'show ' : 'hide'">
              {{ item.heading }}
              </span>
          </v-col>
          <v-col
            cols="6"
            class="text-center">
          </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="item.children && DRAWER_STATE"
          :key="item.title"
          v-model="item.model"
          append-icon="">
            <template v-slot:prependIcon>
              <v-icon size="28">mdi-image-filter-none</v-icon>
            </template>
            <template v-slot:activator >
              <v-list-item-content >
                <v-list-item-title
                  class="grey--text">
                    {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :to="child.link"
              link>
                <v-list-item-action v-if="child.icon">
                  <v-icon size="">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="grey--text">
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
        <v-list-item
          color="primary"
          v-else
          :key="item.text"
          :href="item.href ? item.href : null"
          :to="item.link === '#' ? null : item.link"
          link>
          <v-list-item-action>
            <v-icon
              size="28"
              :color="item.color ? item.color : ''"
            >{{ item.icon }}</v-icon>
          </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="grey--text"
                link>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </div>
      </template>
    </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'

  export default {
    props: {
        source: String,
    },
    data(){
      return {
        items: [
          { title: 'Dashboard', icon: 'mdi-home', link: '/dashboard', roles: 'NASABAH', roles2: 'KOLEKTOR', roles3: 'ADMIN' },
          { divider: true, roles: 'NASABAH', roles2: 'KOLEKTOR'  , roles3: 'ADMIN' },
          { heading: 'SIMPANAN', roles: 'NASABAH', roles2: 'KOLEKTOR' , roles3: 'ADMIN' },
          { title: 'Daftar Simpanan', icon: 'mdi-book-variant-multiple', link: '/datasimpananadmin', roles: 'ADMIN' },
          // { title: 'Mutasi Simpanan', icon: 'mdi-account', link: '/mutasisimpanankolektor', roles: 'ADMIN'},
          { title: 'Transaksi Harian', icon: 'mdi-cash', link: '/transaksiharianadmin', roles: 'ADMIN'},

          { title: 'Data Simpanan', icon: 'mdi-book-variant-multiple', link: '/datasimpanan', roles: 'NASABAH' },
          { title: 'Mutasi Simpanan', icon: 'mdi-account', link: '/mutasisimpanan', roles: 'NASABAH'},

          { title: 'Transaksi Simpanan', icon: 'mdi-cash', link: '/transaksisimpanan', roles: 'KOLEKTOR'},
          { title: 'Mutasi Simpanan', icon: 'mdi-account-switch', link: '/mutasisimpanankolektor', roles: 'KOLEKTOR'},

          { divider: true, roles: 'NASABAH', roles2: 'KOLEKTOR', roles3: 'ADMIN'  },
          { heading: 'PINJAMAN', roles: 'NASABAH', roles2: 'KOLEKTOR', roles3: 'ADMIN'  },
          { title: 'Pengajuan Pinjaman', icon: 'mdi-cash', color: 'warning', link: '/pengajuanpinjaman', roles: 'NASABAH'},
          { title: 'Daftar Pinjaman', icon: 'mdi-account-cash', color: 'primary', link: '/daftarpinjaman', roles: 'NASABAH'},

          { title: 'Simulasi Pinjaman', icon: 'mdi-account-switch', color: 'primary', link: '/simulasipinjaman', roles: 'KOLEKTOR'},
          { title: 'Daftar Kreditur', icon: 'mdi-account-cash', color: 'primary', link: '/daftarkreditur', roles: 'KOLEKTOR'},
          { title: 'Setoran Kreditur', icon: 'mdi-cash', color: 'primary', link: '/setorankreditur', roles: 'KOLEKTOR'},

          { title: 'Simulasi Pinjaman', icon: 'mdi-account-switch', color: 'primary', link: '/simulasipinjaman', roles: 'ADMIN'},
          { title: 'Daftar Pinjaman', icon: 'mdi-account-cash', color: 'primary', link: '/datakredit', roles: 'ADMIN'},
          { title: 'Transaksi Harian', icon: 'mdi-cash', color: 'primary', link: '/transaksipinjamanadmin', roles: 'ADMIN'},

        ],
        sidebarWidth: 240,
        sidebarMinWidth: 96
      }
    },
    computed: {
      ...mapState(['drawer']),
      DRAWER_STATE: {
        get() {
          return this.drawer
        },
        set(newValue) {
          if (newValue === this.drawer) return;
          this.TOGGLE_DRAWER();
        }
      },
      ...mapGetters({
        guest : 'auth/guest',
        user : 'auth/user',
      }),
      
    },
    methods: {
      ...mapActions(
      [ 'TOGGLE_DRAWER' ],
      {
        setAuth   : 'auth/set',
        setAlert : 'alert/set',
      }),
    }
  }
</script>

<style src="./Sidebar.scss" lang="scss"/>
